@font-face {
    font-family: 'notera';
    src: url('notera-webfont.eot');
    src: url('/fonts/notera/notera-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/notera/notera-webfont.woff2') format('woff2'),
         url('/fonts/notera/notera-webfont.woff') format('woff'),
         url('/fonts/notera/notera-webfont.ttf') format('truetype'),
         url('/fonts/notera/notera-webfont.svg#notera_personal_use_onlyRg') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'open_sanslight';
    src: url('/fonts/opensans/light/opensans-light-webfont.eot');
    src: url('/fonts/opensans/light/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/light/opensans-light-webfont.woff2') format('woff2'),
         url('/fonts/opensans/light/opensans-light-webfont.woff') format('woff'),
         url('/fonts/opensans/light/opensans-light-webfont.ttf') format('truetype'),
         url('/fonts/opensans/light/opensans-light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'open_sanslight_italic';
    src: url('/fonts/opensans/light_italic/opensans-lightitalic-webfont.eot');
    src: url('/fonts/opensans/light_italic/opensans-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/light_italic/opensans-lightitalic-webfont.woff2') format('woff2'),
         url('/fonts/opensans/light_italic/opensans-lightitalic-webfont.woff') format('woff'),
         url('/fonts/opensans/light_italic/opensans-lightitalic-webfont.ttf') format('truetype'),
         url('/fonts/opensans/light_italic/opensans-lightitalic-webfont.svg#open_sanslight_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'open_sansregular';
    src: url('/fonts/opensans/regular/opensans-regular-webfont.eot');
    src: url('/fonts/opensans/regular/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/regular/opensans-regular-webfont.woff2') format('woff2'),
         url('/fonts/opensans/regular/opensans-regular-webfont.woff') format('woff'),
         url('/fonts/opensans/regular/opensans-regular-webfont.ttf') format('truetype'),
         url('/fonts/opensans/regular/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'open_sanssemibold';
    src: url('/fonts/opensans/semi_bold/opensans-semibold-webfont.eot');
    src: url('/fonts/opensans/semi_bold/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/opensans/semi_bold/opensans-semibold-webfont.woff2') format('woff2'),
         url('/fonts/opensans/semi_bold/opensans-semibold-webfont.woff') format('woff'),
         url('/fonts/opensans/semi_bold/opensans-semibold-webfont.ttf') format('truetype'),
         url('/fonts/opensans/semi_bold/opensans-semibold-webfont.svg#open_sanssemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}